// React Redux Core
import React from 'react';
import ReactDOM from 'react-dom';
// Material-UI and other Libraries
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
// Adapify
import theme from './theme';
import App from './App';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CssBaseline />
        <App />
      </MuiPickersUtilsProvider>
  </ThemeProvider>,
  rootElement,
);
