// React Core
import React, { useState } from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// Icons
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import StarIcon from '@material-ui/icons/Star';
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/partnerBanner.jpg)`,
    backgroundSize: 'cover',
    height: 600,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 80,
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  cirlceImg: {
    borderRadius: '50%',
    width: 240,
    height: 240,
    margin: '0 auto',
    display: 'block',
  },
  contactWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  contact: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '90%',
  },
  contactContent: {
    padding: 2,
    margin: '40px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mssgContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    alignItems: 'center',
    [theme.breakpoints.down(900)]: {
      width: '100%',
    },
  },
  otherHalf: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    textAlign: 'center',
    justifyContent: 'center',
    height: 400,
    [theme.breakpoints.down(900)]: {
      width: '100%',
      height: 'auto',
      marginTop: 10,
    },
  },
  contactCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'row',
    },
  },
  messageField: {
    fill: '#fff',
    width: '92%',
    height: 50,
    [theme.breakpoints.down(900)]: {
      width: '87%'
    },
  },
  buttonWrapper: {
    marginTop: 30,
  },
  topFields: {
    marginTop: 20,
    marginBottom: 0,
    width: '95%',
    padding: '0 2.5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  topField: {
    width: '49%',
  },
  botField: {
    width: '90%',
  },
  priceCards: {
    width: '100%',
    margin: '0 auto',
  },
  starCards: {
    display: 'flex',
    width: '100%',
    maxWidth: 980,
    justifyContent: 'center',
    padding: 0,
    margin: '0 auto',
    [theme.breakpoints.down(800)]: {
      maxWidth: 300,
      display: 'block',
      margin: '0 auto',
    },
  },
  starCard: {
    display: 'block',
    padding: 40,
    margin: 20,
    [theme.breakpoints.up(800)]: {
      width: '33%'
    },
  },
  list: {
    justifyContent: 'center',
  },
  listItem: {
    textAlign: 'center',
    display: 'block',
    '& .MuiListItemText-root': {
      display: 'inline-flex',
      flex: 'auto',
    },
    '& .MuiListItemIcon-root': {
      display: 'inline-flex',
      justifyContent: 'center',
      '& svg': {
        top: 5,
        position: 'relative',
      },
    },
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
}));

const Partner = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Partner',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/partner`,
    page_path: '/partner'
  });
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [messageOn, setMessageOn] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [poohCheck, setPoohCheck] = useState(false);
  const changePooh = () => {
    setPoohCheck(true);
  };
  const submitForm = async (data) => {
    gtag('event', 'conversion', {
      'send_to': 'AW-831484399/roqwCNe3h4ACEO_jvYwD'
    });
    gtag('event', 'click', {
      'event_category': data.origin,
      'event_label': 'Partner - Form Submit',
      'value': 'Partner - Form Submit'
    });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    /*data = {
      "origin": "Contact_Form",
      "name": name,
      "phone": phone,
      "email": email,
      "body": custMssg,
      "setMessage": setMessage,
      "messageOn": setMessageOn,
      "data": "{}"
    }*/
    const body = JSON.stringify(data);
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body
    };
    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_BACKSITE_URL}/lead_resource`, requestOptions);

    if (result.status === 200) {
      setMessage('Your form has been submitted. We\'ll be in touch within 24 hours');
      setMessageOn(true);
    }
    if (result.ok === false) {
      setMessage(result.message);
      setMessageOn(true);
    }
  };

  const handleSubmit = () => {
    if ((email.indexOf("@") !== -1) && (email.indexOf(".") !== -1) && (email.length >= 5) && (phone.length === 10)) {
      submitForm({
        "origin": "Partner_Form",
        "name": name,
        "phone": phone,
        "email": email,
        "body": company,
        "setMessage": setMessage,
        "setMessageOn": setMessageOn,
        "data": "{}"
      })
      setSeverity("success");
      setMessage("Your form has been submitted. Someone will reach out within 24 hours.")
      setMessageOn(true);
    } else {
      setSeverity("error");
      setMessage("Please enter a valid email address and a valid ten digit phone number");
      setMessageOn(true);
    }
  };

  return (
    <>
      <CssBaseline />
      <Header current="partner" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              Partnering with Rx Soil
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              You get your own branded soil tests and your own branded site to manage your customers and
              results over time, and we set everything up to recommend your prefered products.
            </Typography>
          </Box>
        </Container>
      </div>

      <Container className={classes.main} maxWidth="lg">
        <Typography variant="h4" align="center" color="textPrimary" paragraph>
          The Private Label Package Includes:
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          for a one-time onboarding and setup fee of $1195
        </Typography>
        <List className={classes.list} component="ul">
          <ListItem className={classes.listItem} component="li" alignItems="center">
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText  primary="Your own personally branded kits and the first 15 are included in the onboarding fee." />
          </ListItem>
          <ListItem className={classes.listItem} component="li" alignItems="center">
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary="Your own personally branded site for your business and employees." />
          </ListItem>
          <ListItem className={classes.listItem} component="li" alignItems="center">
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary="We add the products you carry and provide 24/7 support if anything goes wrong." />
          </ListItem>
          <ListItem className={classes.listItem} component="li" alignItems="center">
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary="Site configuration, hosting, maintanence, security, and updates for your site for life.**" />
          </ListItem>
        </List>
        <div className={classes.priceCards}>
          <div  className={classes.starCards}>
            <Paper className={classes.starCard} elevation={3}>
              <center>
                <img alt="Bronze" width="120px" src={`${process.env.PUBLIC_URL}/images/star5.png`} />
                <Typography variant="h6" align="center" color="textSecondary" paragraph>Bronze</Typography>
                <Typography variant="h5" align="center" paragraph>$42.00/kit</Typography>
                <Typography variant="subtitle" align="center" color="textSecondary" paragraph>
                  <br />( for kits 16-499 )
                  <br />
                  <br />
                  Everything listed in The Starter Package above.
                  <br />
                </Typography>
              </center>
            </Paper>
            <Paper className={classes.starCard} elevation={3}>
              <center>
                <img alt="Silver" width="120px" src={`${process.env.PUBLIC_URL}/images/star2.png`} />
                <Typography variant="h6" align="center" color="textSecondary" paragraph>Silver</Typography>
                <Typography variant="h5" align="center" paragraph>$40.00/kit</Typography>
                <Typography variant="subtitle" align="center" color="textSecondary" paragraph>
                  <br />( for kits 500-999 )
                  <br />
                  <br />
                  Everything in bronze plus improved kit pricing.
                  <br />
                </Typography>
              </center>
            </Paper>
            <Paper className={classes.starCard} elevation={3}>
              <center>
                <img alt="Gold" width="120px" src={`${process.env.PUBLIC_URL}/images/star4.png`} />
                <Typography variant="h6" align="center" color="textSecondary" paragraph>Gold</Typography>
                <Typography variant="h5" align="center" paragraph>$38.00/kit</Typography>
                <Typography variant="subtitle" align="center" color="textSecondary" paragraph>
                  <br />( for kits 1000+ )
                  <br />
                  <br />
                  Options For:
                  <br />
                  bulk pricing, enhanced support, Android and iOS apps, and more.
                </Typography>
              </center>
            </Paper>
          </div>
          <Typography variant="caption" align="center" color="textSecondary" paragraph>** Must purchase a minimum of 50 kits per year for service to remain active.</Typography>
        </div>
        <br />
      </Container>

      <Container className={classes.main} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <img alt="Call Us" className={classes.cirlceImg} src={`${process.env.PUBLIC_URL}/images/partner1.png`} />
          </div>
          <div className={classes.section}>
            <Typography variant="h4" align="center" color="textPrimary" paragraph>
              844-797-6454
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Don't need private labeling? Call and ask us about wholesale pricing for Rx Soil branded Kits.
            </Typography>
          </div>
        </div>
      </Container>

      <Container className={classes.contactWrapper}>
        <div className={classes.contact}>
          <div className={classes.contactContent}>
            <Container className={classes.mssgContainer}>
              <Typography variant="h5">Learn About Partnering With Rx Soil</Typography>
              <Container className={classes.topFields}>
                <TextField
                  className={classes.topField}
                  variant="outlined"
                  margin="normal"
                  name="name"
                  label="Name"
                  type="name"
                  id="name"
                  autoComplete="name"
                  color="primary"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  className={classes.topField}
                  variant="outlined"
                  margin="normal"
                  name="phone"
                  label="Phone"
                  type="phone"
                  id="phone"
                  autoComplete="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Container>
              <TextField
                className={classes.botField}
                variant="outlined"
                margin="normal"
                name="email"
                label="Email"
                type="email"
                id="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                className={[classes.messageField, classes.botField]}
                variant="outlined"
                margin="normal"
                name="company"
                label="Company Name"
                id="company"
                autoComplete="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
              <div className={classes.checkWrapper}>
                Do you agree to our terms and conditions?
                <Checkbox checked={poohCheck} onChange={changePooh} />
              </div>
              <Fade
                in={messageOn}
                timeout={500}
                unmountOnExit
              >
                <Alert severity={severity}>
                  {message}
                </Alert>
              </Fade>
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.emailButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleSubmit()}
                >
                  Send Message
                </Button>
              </div>
            </Container>
            <Container className={classes.otherHalf}>
              <Container style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 35
              }} >
                <Container className={classes.contactCont} style={{ marginBottom: 20 }}>
                  <Typography variant="subtitle2">
                    Fill out the form and we will contact you within 24hrs. Or you can call or the number below, or send us an email.
                  </Typography>
                </Container>
                <Container className={classes.contactCont}>
                  <CallIcon fontSize="medium" style={{ marginRight: 6 }} color="secondary" />
                  <h4> 844-797-6454 </h4>
                </Container>
                <Container className={classes.contactCont}>
                  <EmailIcon fontSize="medium" style={{ marginRight: 8 }} color="secondary" />
                  <h4> info@rxsoil.com </h4>
                </Container>
              </Container>
            </Container>
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default Partner;
