// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/algBanner.jpg)`,
    backgroundSize: 'cover',
    height: 420,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 40,
  },
  threeSections: {
    margin: '80px 0',
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'left',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: 'auto',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  cirlceImg: {
    borderRadius: '50%',
    width: 240,
    height: 240,
    margin: '0 auto',
    display: 'block',
  },
}));

const RxSoilAlgorithm = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'RxSoil Algorithm',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/rxsoil-algorithm`,
    page_path: '/rxsoil-algorithm'
  });
  return (
    <>
      <CssBaseline />
      <Header current="rxsoil" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              The RxSoil Algorithm
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              Our incredibly powerful algorithm transforms a 4oz Soil Analysis sample into a customized scientific
              12 month Soil Management plan.
            </Typography>
          </Box>
        </Container>
      </div>

      <Container className={classes.main} maxWidth="lg">
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Customized Scientific 12 Month Instructional Soil Management Plan
          <br />
          <br />
              Science: Determines the Problems.
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Our algorithm understands the problems and prescribes the solutions, then your store instructs the customers and
          sells the products they need to have the perfect Lawn, Garden, or Landscape.
        </Typography>
      </Container>

      <Container className={classes.main} maxWidth="lg">
        <div className={classes.threeSections}>
          <div className={classes.section}>
            <Avatar className={classes.number}>1</Avatar>
          </div>
          <div className={classes.section}>
            <Typography variant="h5" align="left" color="textPrimary" paragraph>
              How it Works
            </Typography>
            <Typography variant="h6" align="left" color="textSecondary" paragraph>
              The RxSoil Algorithm utilizes a collection of three data elements: soil test results,
              plant type, and size of the cultivated area to provide your homeowner with a customized 12 month Soil Management
              plan.
              <br />
              <br />
              The L&GC – Homeowner Team is given the tools to understand exactly what nutrients to apply and in amounts that
              will enable the customer to save money while simultaneously ensuring optimal results.  You’ll be selling value
              and not just a bag of fertilizer.
              <br />
              <br />
              Your DIY Customer will also be given the peace-of-mind knowing the recommended nutrients can be applied in a
              responsible and earth-friendly manner.
            </Typography>
          </div>
          <div className={classes.section}>
            <img alt="Call Us" className={classes.cirlceImg} src={`${process.env.PUBLIC_URL}/images/alg1.jpg`} />
          </div>
        </div>
        <div className={classes.threeSections}>
          <div className={classes.section}>
            <Avatar className={classes.number}>2</Avatar>
          </div>
          <div className={classes.section}>
            <Typography variant="h5" align="left" color="textPrimary" paragraph>
              A Complete Set of Tools
            </Typography>
            <Typography variant="h6" align="left" color="textSecondary" paragraph>
              The RxSoil Algorithm and its associated User Interfaces (UIs) are a Cloud-based Nutrient Management Service engineered to provide you and your DIY customers with scientifically derived soil analyses and customized multi-season nutrient and amendment recommendations.
              <br />
              <br />
              Our platform also provides your Staff with a Support & Administrative Dashboard designed to facilitate data input and on-line editing. The Dashboard includes three User Interfaces (UIs) engineered to provide the easy input of product information, a method to reorder test kits, and an inventory control and tracking module designed to help in the management of your soil testing business.
            </Typography>
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary="Business UI:"
                  secondary="Used to record unique company-specific data, address, contact information, those authorized to access the system, etc."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Product ID UI:"
                  secondary="A user-friendly means of loading/editing product descriptions, images, and specification data."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Recommendation UI:"
                  secondary="Mechanism to record conditional parameters programmed to calculate the amount of product required to achieve optimal Parts Per Million (PPM) element levels applied by season, plant type, and cultivated area."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Administration Tools:"
                  secondary="The Dashboard provides you and your staff with tools necessary to track each kit through the analysis process, as well as supplies a repository of completed tests and recommendations, facilitating their retrieval for use in following seasons. The customer database offers you tools to send customer reminders or notifications on specials and other incentives designed to bring the customer back into your store."
                />
              </ListItem>
            </List>
          </div>
          <div className={classes.section}>
            <img alt="Call Us" className={classes.cirlceImg} src={`${process.env.PUBLIC_URL}/images/alg2.png`} />
          </div>
        </div>
        <div className={classes.threeSections}>
          <div className={classes.section}>
            <Avatar className={classes.number}>3</Avatar>
          </div>
          <div className={classes.section}>
            <Typography variant="h5" align="left" color="textPrimary" paragraph>
              4-Seasons of Success
            </Typography>
            <Typography variant="h6" align="left" color="textSecondary" paragraph>
              Our RxSoil Algorithm exclusively presents all the nutrient and amendment products necessary to both remediate the soil and to then maintain your customer’s lawn, garden or landscape. And not just in the season of initial purchase but throughout the entire year.
              <br />
              <br />
              The repository of customers who have done tests in the past can be used to send timely and appreciated reminders that will keep your soil test customers coming back to the store year-around. Returning customers that afford you an opportunity to consultatively sell and clearly differentiate your business from the competition.
            </Typography>
          </div>
          <div className={classes.section}>
            <img alt="Call Us" className={classes.cirlceImg} src={`${process.env.PUBLIC_URL}/images/alg3.jpg`} />
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default RxSoilAlgorithm;
