// React Redux Core
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// Adapify
import Website from './Website';
import Contact from './Website/Contact';
import Support from './Website/Support';
import Partner from './Website/Partner';
import Invite from './Website/Invite';
import SqftTool from './Website/SqftTool';
import SolutionGuide from './Website/SolutionGuide';
import SoilAnalysis from './Website/SoilAnalysis';
import RxSoilAlgorithm from './Website/RxSoilAlgorithm';

const App = () => {

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/rxsoil-algorithm" component={RxSoilAlgorithm} />
        <Route path="/soil-analysis" component={SoilAnalysis} />
        <Route path="/guide" component={SolutionGuide} />
        <Route path="/sqft" component={SqftTool} />
        <Route path="/partner" component={Partner} />
        <Route path="/support" component={Support} />
        <Route path="/contact" component={Contact} />
        <Route path="/invite" component={Invite} />
        <Route path="/" component={Website} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
