// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/guideBanner.jpg)`,
    backgroundSize: 'cover',
    height: 420,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 40,
  },
  threeSections: {
    margin: '80px 0',
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'left',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: 'auto',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  cirlceImg: {
    borderRadius: '50%',
    width: 240,
    height: 240,
    margin: '0 auto',
    display: 'block',
  },
  videoSection: {
    marginTop: 50,
    marginBottom: 80,
  },
  videoWrapper: {
    marginTop: 60,
  },
  video: {
    borderRadius: 4,
    width: 560,
    height: 315,
    [theme.breakpoints.up(901)]: {
      width: 650,
      height: 365,
    },
    [theme.breakpoints.down(651)]: {
      width: 420,
      height: 234,
    },
    [theme.breakpoints.down(500)]: {
      width: 300,
      height: 167,
    },
  },
}));

const SolutionGuide = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Solution Guide',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/guide`,
    page_path: '/guide'
  });
  return (
    <>
      <CssBaseline />
      <Header current="guide" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="left" color="textSecondary" paragraph>
              Follow the steps below to take a self guided tour
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="left" color="textSecondary" paragraph>
              Or contact us if you have any questions or would like a live demo from one of our team.
            </Typography>
          </Box>
        </Container>
      </div>

      <Container className={classes.main} maxWidth="lg">
        <Typography variant="h4" align="center" color="textSecondary" paragraph>
          Coming Soon...
        </Typography>
      </Container>


      <Footer />
    </>
  );
};

export default SolutionGuide;
