// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/soilBanner.jpg)`,
    backgroundSize: 'cover',
    height: 420,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  ctaForm: {
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      minWidth: 582,
    },
  },
  cta2Form: {
    padding: 16,
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      padding: 0,
      minWidth: 582,
    },
  },
  videoSection: {
    marginTop: 50,
  },
  videoWrapper: {
    marginTop: 60,
  },
  video: {
    borderRadius: 4,
    width: 560,
    height: 315,
    [theme.breakpoints.up(901)]: {
      width: 650,
      height: 365,
    },
    [theme.breakpoints.down(651)]: {
      width: 420,
      height: 234,
    },
    [theme.breakpoints.down(500)]: {
      width: 300,
      height: 167,
    },
  },
  secondCta: {
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  sectionIcon: {
    margin: '0 20px',
    width: '30%',
    [theme.breakpoints.down(900)]: {
      margin: '0 auto',
      '& img': {
        width: 75,
        display: 'block',
        height: 'auto',
      },
    },
    '& img': {
      maxWidth: 200,
    },
  },
  sectionIconMob: {
    display: 'none',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      display: 'block',
      width: '100%',
      '& img': {
        width: 75,
        display: 'block',
        height: 'auto',
      },
    },
    '& img': {
      display: 'block',
      margin: '0 auto',
      maxWidth: 200,
    },
  },
  sectionIconDesk: {
    display: 'block',
    margin: '0 20px',
    width: '30%',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    },
    '& img': {
      maxWidth: 200,
    },
  },
  sectionText: {
    margin: '0 20px',
    width: '70%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  mainAlert: {
    '& .MuiAlertTitle-root': {
      fontWeight: 600,
      fontSize: 24,
      color: '#fff',
    },
    '& .MuiSvgIcon-root': {
      fill: '#fff',
      width: 30,
      height: 30,
    },
    backgroundColor: '#7ab0de',
    width: '80%',
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: 50,
    fontSize: 20,
    fontWeight: 500,
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  boxImg: {
    height: 'auto',
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
    [theme.breakpoints.up(501)]: {
      width: 420,
    },
  },
  productName: {
    fontWeight: 600,
  },
  productPrice: {
    fontWeight: 600,
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  main: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: 80,
    '& .MuiGrid-root.MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    [theme.breakpoints.down(510)]: {
      padding: 10,
      '& h4': {
        marginTop: 40,
        marginBottom: 40,
        fontSize: 20,
      },
    },
  },
  analysis: {
    margin: '50px 0',
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  bottomCTA: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  mainSection: {
    height: 440,
    width: 'auto',
    margin: 40,
    padding: 20,
    [theme.breakpoints.down(900)]: {
      height: 'auto',
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  instructionsImg: {
    width: '100%',
    maxWidth: 840,
    height: 'auto',
    margin: '40px auto',
    display: 'block',
  },
  recommendationsImg: {
    width: '100%',
    maxWidth: 550,
    height: 'auto',
    margin: '40px auto 0',
    display: 'block',
  },
  bagImg: {
    width: 'auto',
    height: '100%',
    maxHeight: 400,
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(840)]: {
      maxWidth: 360,
      width: '100%',
      height: 'auto',
    },
  },
  shipImg: {
    width: 'auto',
    height: '100%',
    maxHeight: 340,
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(840)]: {
      maxWidth: 480,
      width: '100%',
      height: 'auto',
    },
  },
  fabContainer: {
    zIndex: 1000,
    width: 350,
    height: 500,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const SoilAnalysis = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Soil Analysis',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/soil-analysis`,
    page_path: '/soil-analysis'
  });
  return (
    <>
      <CssBaseline />
      <Header current="soil" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              Introducing Tools to Empower your Customer Recommedations.
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              Detailed analysis of any Lawn, Garden, or Landsdcape is simple & easy !
            </Typography>
          </Box>
        </Container>
      </div>

      <div className={classes.main}>
        <Typography variant="h4" align="center" gutterBottom>
          What&apos;s inside a Rx Soil Analysis Kit?
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          Easy to understand instruction card:
        </Typography>
        <img
          alt="Four step instruction card for sampling soil and sending it to the lab"
          height="570"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/4x6instructionsFront_B2B.jpg`}
          loading="lazy"
        />
        <br />
        <div className={classes.twoSections}>
          <div className={classes.mainSection}>
            <Typography variant="h5" align="center" paragraph>
              Compostable soil collection bag:
            </Typography>
            <img
              alt="Soil collection bag"
              height="400"
              width="345"
              className={classes.bagImg}
              src={`${process.env.PUBLIC_URL}/images/bag.webp`}
              loading="lazy"
            />
          </div>
          <div className={classes.mainSection}>
            <Typography variant="h5" align="center" paragraph>
              Pre-paid shipping label to our lab:
            </Typography>
            <img
              alt="Soil analysis kit with prepaid shipping label"
              height="340"
              width="495"
              className={classes.shipImg}
              src={`${process.env.PUBLIC_URL}/images/b2cBoxBack.webp`}
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div className={classes.analysis}>
        <Typography variant="h4" align="center" gutterBottom>
          Simple to Understand Results
        </Typography>
        <br />
        <br />
        <img
          alt="Soil analysis graph and chart showing optimal ranges"
          height="942"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/soil-analysis.webp`}
          loading="lazy"
        />
        <br />
      </div>

      <div className={classes.main}>
        <Typography variant="h4" align="center" gutterBottom>
          Advanced In-Depth Recommendations
        </Typography>
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.sectionIcon}>
              <img
                alt="Grid with plot marks"
                height="200"
                width="200"
                src={`${process.env.PUBLIC_URL}/images/growingArea.webp`}
                loading="lazy"
              />
            </div>
            <div className={classes.sectionText}>
              <Typography variant="h6" align="left" color="textPrimary" paragraph>
                First, we gather some information about your growing area such as square footage and
                location.
              </Typography>
            </div>
          </div>
        </Container>
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.sectionIconMob}>
              <img
                alt="Plant labeled with growth markers"
                height="200"
                width="200"
                src={`${process.env.PUBLIC_URL}/images/plant.webp`}
                loading="lazy"
              />
            </div>
            <div className={classes.sectionText}>
              <Typography variant="h6" align="left" color="textPrimary" paragraph>
                Then, you select the type of plants that you&apos;re growing and your preferred
                products.
              </Typography>
            </div>
            <div className={classes.sectionIconDesk}>
              <img
                alt="Plant labeled with growth markers"
                height="200"
                width="200"
                src={`${process.env.PUBLIC_URL}/images/plant.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </Container>
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.sectionIcon}>
              <img
                alt="Computer screen with soil analysis readout"
                height="200"
                width="200"
                src={`${process.env.PUBLIC_URL}/images/soilLab.webp`}
                loading="lazy"
              />
            </div>
            <div className={classes.sectionText}>
              <Typography variant="h6" align="left" color="textPrimary" paragraph>
                The laboratory analysis provides precise major and minor element levels, the
                capacity to retain nutrients, ph level, and more.
              </Typography>
            </div>
          </div>
        </Container>
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.sectionIconMob}>
              <img
                alt="Multi level grid with plot marks"
                height="200"
                width="200"
                src={`${process.env.PUBLIC_URL}/images/soilData.webp`}
                loading="lazy"
              />
            </div>
            <div className={classes.sectionText}>
              <Typography variant="h6" align="left" color="textPrimary" paragraph>
                Finally, we provide you with detailed recommendations for which products to put
                down, when, and how much.
              </Typography>
            </div>
            <div className={classes.sectionIconDesk}>
              <img
                alt="Multi level grid with plot marks"
                height="200"
                width="200"
                src={`${process.env.PUBLIC_URL}/images/soilData.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </Container>
        <img
          alt="Soil amendment recommendation chart showing instructions"
          height="1280"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/recommendations.webp`}
          loading="lazy"
        />
      </div>

      <Footer />
    </>
  );
};

export default SoilAnalysis;
