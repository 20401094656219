// React Core
import React from 'react';
// Material-UI and other Libraries
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
// MUI Icons and Font Awesome
import PhoneIcon from '@material-ui/icons/Phone';
// Adapify
import gtag from '../Utilities/Gtag';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  phoneIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
    marginTop: 4,
    marginRight: 4,
  },
}));

const Footer = () => {
  const classes = useStyles();
  gtag();

  return (
    <>
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          <Grid item xs={6} sm={3} key="Logo">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              <a href="https://rxsoil.com"><img xs={6} sm={3} style={{ maxWidth: 150, height: 'auto', marginTop: 10 }} src={`${process.env.PUBLIC_URL}/logo-wide.webp`} alt="logo" /></a>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} key="company">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Company
            </Typography>
            <ul>
              <li key="contact">
                <Link href="/contact" variant="subtitle1" color="textSecondary"
                onClick={() => {
                  gtag('event', 'click', {
                    'event_category': 'Footer - Contact Link Clicked',
                    'event_label': 'Footer - Contact Link Clicked',
                    'value': 'Footer - Contact Link Clicked'
                  });
                }}>
                  Contact
                </Link>
              </li>
              <li key="phone">
                <Typography variant="button" color="textSecondary" gutterBottom>
                  <PhoneIcon className={classes.phoneIcon} /> 844-797-6454
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={3} key="services">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Services
            </Typography>
            <ul>
              <li key="support">
                <Link href="/support" variant="subtitle1" color="textSecondary"
                onClick={() => {
                  gtag('event', 'click', {
                    'event_category': 'Footer - Support Link Clicked',
                    'event_label': 'Footer - Support Link Clicked',
                    'value': 'Footer - Support Link Clicked'
                  });
                }}>
                  Support
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
        <br />
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            Made in the USA
            <br />
            {'© 2014-'}
            {`${new Date().getFullYear()} `}
            <Link color="primary" href="https://rxsoil.com/"
            onClick={() => {
              gtag('event', 'click', {
                'event_category': 'Footer - Precription Soil Analysis Link Clicked',
                'event_label': 'Footer - Precription Soil Analysis Link Clicked',
                'value': 'Footer - Precription Soil Analysis Link Clicked'
              });
            }}>
              Adapify, Inc.
            </Link>
            {' All Rights Reserved.'}
          </Typography>
          <br />
        </Box>
      </Container>
      {/* End footer */}
    </>
  );
};

export default Footer;
