// React Core
import React from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
// MUI Icons and Font Awesome
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0, 6),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/B2BBanner.png)`,
    backgroundPositionX: '65%',
    backgroundSize: 'cover',
    height: 710,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'left',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  boxButton: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  emailButton: {
    height: 55,
    marginLeft: 10,
    [theme.breakpoints.down(651)]: {
      marginTop: 15,
    },
  },
  main: {
    marginTop: 10,
    marginBottom: 40,
    padding: 40,
    '& .MuiGrid-root.MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    '& .MuiTypography-h5': {
      fontWeight: 600,
    },
  },
  ctaForm: {
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      minWidth: 582,
    }
  },
  cta2Form: {
    padding: 16,
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      padding: 0,
      minWidth: 582,
    }
  },
  cards: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
  },
  card: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
  },
  videoSection: {
    marginTop: 50,
  },
  videoWrapper: {
    marginTop: 60,
  },
  video: {
    borderRadius: 4,
    width: 560,
    height: 315,
    [theme.breakpoints.up(901)]: {
      width: 650,
      height: 365,
    },
    [theme.breakpoints.down(651)]: {
      width: 420,
      height: 234,
    },
    [theme.breakpoints.down(500)]: {
      width: 300,
      height: 167,
    },
  },
  secondCta: {
    marginTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  cirlceImg: {
    borderRadius: '50%',
    width: 240,
    height: 240,
    margin: '0 auto',
    display: 'block',
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  priceCards: {
    width: '100%',
    margin: '0 auto',
  },
  starCards: {
    display: 'flex',
    width: '100%',
    maxWidth: 980,
    justifyContent: 'center',
    padding: 0,
    margin: '0 auto',
    [theme.breakpoints.down(800)]: {
      maxWidth: 300,
      display: 'block',
      margin: '0 auto',
    },
  },
  starCard: {
    display: 'block',
    padding: 40,
    margin: 20,
    [theme.breakpoints.up(800)]: {
      width: '33%'
    },
  },
  list: {
    justifyContent: 'center',
  },
  listItem: {
    textAlign: 'center',
    display: 'block',
    '& .MuiListItemText-root': {
      display: 'inline-flex',
      flex: 'auto',
    },
    '& .MuiListItemIcon-root': {
      display: 'inline-flex',
      justifyContent: 'center',
      '& svg': {
        top: 5,
        position: 'relative',
      },
    },
  },
  priceListStyling: {
    listStyleType: 'disc',
    textAlign: 'center',
    listStylePosition: 'inside',
    '& li': {
      marginBottom: '10px',
    },
  },
}));

const Website = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Home',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/`,
    page_path: '/'
  });

  const sendToForm = () => {
    window.location.replace(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/contact`);
  };

  return (
    <>
      <CssBaseline />
      <Header current="home" />
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
              Rx Soil partners with:
            </Typography>
            <List dense={false}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Lawn & Garden Centers"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Landscaping Businesses"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Fertilizer Manufacturers"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Farmers & Growers"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Universities & Researchers"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Influencers & Industry Experts"
                />
              </ListItem>
            </List>
          </Box>
        </Container>
      </div>
      {/* End hero unit */}

      <Container className={classes.main} maxWidth="lg">
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Our solution is designed to start with a soil test kit, and your customers can get started
          in any season. Whether sold online, in-person, or over the counter, Rx Soil provides our
          partners with simple ways to order and inventory test kits, along with the online experience
          to track and engage with your customers when their results are ready. When it's time to
          apply products, we provide laboratory results on 13 factors related to soil health, and the
          recommendations are programmed with your preferred products to show what, when, and
          exactly how much should be applied, each season over the next year.
        </Typography>
        <div className={classes.ctaForm}>
          <Button
            className={classes.emailButton}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => sendToForm()}
          >
            Get Started Today!
          </Button>
        </div>
      </Container>

      <Container className={classes.cards} maxWidth="lg">
        <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="Lawn and Garden Centers"
            height="283"
            src={`${process.env.PUBLIC_URL}/images/Box2.png`}
            title="Lawn and Garden Centers"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Lawn and Garden Centers
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Personally branded soil test, that recommends the product you carry in you retail store.
              Each customer that purchases a soil test recieves a detailed Nutrient Management Plan for
              each season. Empower your staff to always give the best recommendations for each customer.
              You can choose to direct customers to your brick and mortar or e-commerce store.
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="Landscaping Businesses"
            height="283"
            src={`${process.env.PUBLIC_URL}/images/Box1.png`}
            title="Landscaping Businesses"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Landscaping Businesses
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Our software provide you with a complete solution to ensure each customer has the optimal
              growing conditions all year round. Reduce product cost by not over-fertilizing, quickly diagnose
              problems, and keep historical data for each customer what was applied and when. Just take a soil
              sample and drop it in their mailbox, we'll email you results in less than a week.
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="Fertilzer Manufacturers"
            height="283"
            src={`${process.env.PUBLIC_URL}/images/Box3.png`}
            title="Fertilzer Manufacturers"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Fertilzer Manufacturers
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Your specific recommendations tailored for the best results. Use real world data to see the
              effects of your recommendations on your customers soil and plant health. Promote your brand and
              products, each soil test increases the likelyhood that customer puchases your brand over a competitors
              for all of their soil ammendment needs.
            </Typography>
          </CardContent>
        </Card>
      </Container>

      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Typography variant="h4" align="center" color="textPrimary" paragraph>
              Empower Your Staff, Increase Sales
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Enable your staff to provide each customer with a detailed plan based on their
              unique soil needs, detailing which of your preferred products should be applied,
              in what amounts, and when.
            </Typography>
          </div>
        </div>
      </Container>

      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Typography variant="h4" align="center" color="textPrimary" paragraph>
              Save Time, Get Better Results
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Save years of guess work on your customers' properties by providing afforable
              laboratory analysis today. Our reports get down to root cause of nutrient imbalances
              quickly, with results returned in about a week.
            </Typography>
          </div>
        </div>
      </Container>

      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Typography variant="h4" align="center" color="textPrimary" paragraph>
              Increase Revenue through Science
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              With testing packages for nutrient levels, texture analysis, contaminant testing,
              and more, reinforce your scientific credibility with your customers by adding
              soil testing to your existing services.
            </Typography>
          </div>
        </div>
      </Container>

      <Container className={classes.main} maxWidth="lg">
        <Typography variant="h4" align="center" color="textPrimary" paragraph>
          Pricing Options Available for All Types of Businesses
        </Typography>

        <div className={classes.priceCards}>
          <div  className={classes.starCards}>
            <Paper className={classes.starCard} elevation={3}>
              <center>
                <img alt="Bronze" width="120px" src={`${process.env.PUBLIC_URL}/images/star5.png`} />
                <Typography variant="h6" align="center" color="textPrimary" paragraph>Resell Rx Soil Nutrient Test Kits</Typography>
                <Typography variant="h5" align="center" paragraph>$700</Typography>
                <Typography variant="subtitle" align="center" color="textPrimary" paragraph>
                  <br />Resell Rx Soil branded nutrient test kits to your customers
                  <br />
                  <br />
                  <ul className={classes.priceListStyling}>
                    <li>
                      Includes 20 nutrient analysis kits
                    </li>
                  </ul>
                </Typography>
              </center>
            </Paper>
            <Paper className={classes.starCard} elevation={3}>
              <center>
                <img alt="Silver" width="120px" src={`${process.env.PUBLIC_URL}/images/star2.png`} />
                <Typography variant="h6" align="center" color="textPrimary" paragraph>Custom Kits and Recommendations</Typography>
                <Typography variant="h5" align="center" paragraph>$1,500</Typography>
                <Typography variant="subtitle" align="center" color="textPrimary" paragraph>
                <br />One-time setup fee for private-labeled test kits and soil management software
                  <br />
                  <br />
                  <ul className={classes.priceListStyling}>
                    <li>
                      Private-labeled kits
                    </li>
                    <li>
                      Reports recommend your preferred amendment catalog
                    </li>
                    <li>
                      Easy to use software to manage your testing and customers
                    </li>
                    <li>
                      Includes 20 nutrient analysis kits
                    </li>
                    <li>
                      No monthly/yearly fees, order discounted testing as needed
                    </li>
                  </ul>
                </Typography>
              </center>
            </Paper>
            <Paper className={classes.starCard} elevation={3}>
              <center>
                <img alt="Gold" width="120px" src={`${process.env.PUBLIC_URL}/images/star4.png`} />
                <Typography variant="h6" align="center" color="textPrimary" paragraph>Your Soil Testing Partner</Typography>
                <Typography variant="h5" align="center" paragraph>$3,000</Typography>
                <Typography variant="subtitle" align="center" color="textPrimary" paragraph>
                <br />One-time setup fee for private-printed test kits and soil management suite
                  <br />
                  <br />
                  <ul className={classes.priceListStyling}>
                    <li>
                      Private-printed kits
                    </li>
                    <li>
                      Reports recommend your preferred amendment catalog
                    </li>
                    <li>
                      Easy to use software to manage your testing and customers
                    </li>
                    <li>
                      1st-level support to you and your customers
                    </li>
                    <li>
                      Testing strategy consulting and tools
                    </li>
                    <li>
                      Seasonal marketing data to drive sales
                    </li>
                    <li>
                      Includes 20 nutrient analysis kits
                    </li>
                    <li>
                      No monthly/yearly fees, order discounted testing as needed
                    </li>
                  </ul>
                </Typography>
              </center>
            </Paper>
          </div>
        </div>
        <br />
      </Container>

      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <img alt="Call Us" className={classes.cirlceImg} src={`${process.env.PUBLIC_URL}/images/call.png`} />
          </div>
          <div className={classes.section}>
            <Typography variant="h4" align="center" color="textPrimary" paragraph>
              <EmailIcon /> info@rxsoil.com
              <br></br>
              <PhoneIcon /> 844-797-6454
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Contact us today if you have any questions or want to see a live demo.
            </Typography>
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default Website;
