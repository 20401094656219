// React Core
import React, { useState } from 'react';
// Material-UI and other Libraries
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fade from '@material-ui/core/Fade';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// Icons
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/inviteBG.jpg)`,
    backgroundPositionY: '50%',
    backgroundSize: 'cover',
    height: 600,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'right',
      margin: '100px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 80,
    marginBottom: 80,
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  cirlceImg: {
    borderRadius: '50%',
    width: 240,
    height: 240,
    margin: '0 auto',
    display: 'block',
  },
  contactWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  contact: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '90%',
  },
  contactContent: {
    padding: 2,
    margin: '40px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mssgContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    alignItems: 'center',
    [theme.breakpoints.down(900)]: {
      width: '100%',
    },
  },
  otherHalf: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    textAlign: 'center',
    justifyContent: 'center',
    height: 400,
    [theme.breakpoints.down(900)]: {
      width: '100%',
      height: 'auto',
      marginTop: 10,
    },
  },
  contactCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'row',
    },
  },
  messageField: {
    fill: '#fff',
    width: '92%',
    height: 50,
    [theme.breakpoints.down(900)]: {
      width: '87%'
    },
  },
  buttonWrapper: {
    marginTop: 10,
  },
  topFields: {
    marginTop: 20,
    marginBottom: 0,
    width: '95%',
    padding: '0 2.5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  topField: {
    width: '49%',
  },
  midFields: {
    marginTop: -10,
    marginBottom: 0,
    width: '95%',
    padding: '0 2.5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  botFields: {
    marginTop: -10,
    marginBottom: 0,
    width: '95%',
    padding: '0 2.5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& div:first-child': {
      marginLeft: -4,
    },
    '& div:last-child': {
      marginRight: -2,
    },
  },
  botField: {
    padding: '0 4px',
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
}));

const Invite = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [org, setOrg] = useState('');
  const [title, setTitle] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [message, setMessage] = useState('');
  const [messageOn, setMessageOn] = useState(false);
  const [severity, setSeverity] = useState('error');
  const [poohCheck, setPoohCheck] = useState(false);
  const changePooh = () => {
    setPoohCheck(true);
  };
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Invite',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/invite`,
    page_path: '/invite'
  });

  const submitForm = async (data) => {
    gtag('event', 'click', {
      'event_category': data.origin,
      'event_label': 'Invite - Form Submit',
      'value': 'Invite - Form Submit'
    });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    /*data = {
      "origin": "Invite_Form",
      "name": name,
      "email": email,
      "data": `{
        organization: ${org},
        jobTitle: ${title},
        city: ${city},
        state: ${state},
        zip: ${zip},
      }`
    }*/
    const body = JSON.stringify(data);
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body
    };
    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_BACKSITE_URL}/lead_resource`, requestOptions);

    if (result.status === 200) {
    }
    if (result.ok === false) {
    }
  };

  const handleSubmit = () => {
    if ((name.length < 3) || (name.includes(" ") === false)) {
      setSeverity("error");
      setMessage("Please enter First and Last Name separted by a space");
      setMessageOn(true);
    } else if ((email.includes("@") === false) || (email.includes(".") === false) || (email.length < 5)) {
      setSeverity("error");
      setMessage("Please enter a valid email address");
      setMessageOn(true);
    } else if ((org.length < 1)) {
      setSeverity("error");
      setMessage("Please enter an organization");
      setMessageOn(true);
    } else if ((title.length < 1)) {
      setSeverity("error");
      setMessage("Please enter a job title");
      setMessageOn(true);
    } else if ((city.length < 1)) {
      setSeverity("error");
      setMessage("Please enter a city name");
      setMessageOn(true);
    } else if (state.length !== 2) {
      setSeverity("error");
      setMessage("Please enter the 2 character state abbreviation");
      setMessageOn(true);
    } else if ((isNaN(Number(zip))) || (zip.length !== 5)) {
      setSeverity("error");
      setMessage("Please enter a 5 digit zipcode");
      setMessageOn(true);
    } else {
      submitForm({
        "origin": "Invite_Form",
        "name": name,
        "email": email,
        "data": `{
          organization: ${org},
          jobTitle: ${title},
          city: ${city},
          state: ${state},
          zip: ${zip},
        }`,
      })
      setMessage("Thank you for registering, we'll send you an email with more details soon!");
      setSeverity("success");
      setMessageOn(true);
    }
  };

  return (
    <>
      <CssBaseline />
      <Header />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              Join us for a live webinar!
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              Learn about an exiting new way to grow your Lawn and Garden Business on:
              <br />
              <strong>March 4th, 2021 @ 3:00PM EST</strong>
              <br />
              Please fill out the form below to register.
            </Typography>
          </Box>
        </Container>
      </div>

      <Container className={classes.contactWrapper}>
        <div className={classes.contact}>
          <div className={classes.contactContent}>
            <Container className={classes.mssgContainer}>
              <Typography variant="h5">Register for our Webinar</Typography>
              <Container className={classes.topFields}>
                <TextField
                  className={classes.topField}
                  variant="outlined"
                  margin="normal"
                  name="name"
                  label="First and Last Name"
                  type="name"
                  id="name"
                  autoComplete="name"
                  color="primary"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  className={classes.topField}
                  variant="outlined"
                  margin="normal"
                  name="email"
                  label="Email"
                  type="email"
                  id="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Container>
              <Container className={classes.midFields}>
                <TextField
                  className={classes.topField}
                  variant="outlined"
                  margin="normal"
                  name="org"
                  label="Organization"
                  type="org"
                  id="org"
                  autoComplete="org"
                  color="primary"
                  value={org}
                  onChange={(e) => setOrg(e.target.value)}
                />
                <TextField
                  className={classes.topField}
                  variant="outlined"
                  margin="normal"
                  name="title"
                  label="Job Title"
                  type="title"
                  id="title"
                  autoComplete="title"
                  color="primary"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Container>
              <Container className={classes.botFields}>
                <TextField
                  className={classes.botField}
                  variant="outlined"
                  margin="normal"
                  name="city"
                  label="City"
                  type="city"
                  id="city"
                  autoComplete="city"
                  color="primary"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <TextField
                  className={classes.botField}
                  variant="outlined"
                  margin="normal"
                  name="state"
                  label="State"
                  type="state"
                  id="state"
                  autoComplete="state"
                  color="primary"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
                <TextField
                  className={classes.botField}
                  variant="outlined"
                  margin="normal"
                  name="zip"
                  label="Zip Code"
                  type="zip"
                  id="zip"
                  autoComplete="zip"
                  color="primary"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
              </Container>
              <p>By registering, I agree to the <Link href="https://us02web.zoom.us/privacy" target="_blank">privacy statement</Link> and <Link href="https://us02web.zoom.us/terms" target="_blank">terms of service</Link>.</p>
              <div className={classes.checkWrapper}>
                Do you agree to our terms and conditions?
                <Checkbox checked={poohCheck} onChange={changePooh} />
              </div>
              <Fade
                severity={severity}
                in={messageOn}
              >
                <Alert>
                  {message}
                </Alert>
              </Fade>
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.emailButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleSubmit()}
                >
                  Register
                </Button>
              </div>
            </Container>
            <Container className={classes.otherHalf}>
              <Container style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 35
              }} >
                <Container className={classes.contactCont} style={{ marginBottom: 20 }}>
                  <Typography variant="subtitle2">
                    Register for our live webinar on:
                    <br />
                    <strong>March 4th, 2021 @ 3:00PM EST</strong>
                    <br />
                    <br />
                    Or contact us using the info below.
                  </Typography>
                </Container>
                <Container className={classes.contactCont}>
                  <CallIcon fontSize="medium" style={{ marginRight: 6 }} color="secondary" />
                  <h4> 844-797-6454 </h4>
                </Container>
                <Container className={classes.contactCont}>
                  <EmailIcon fontSize="medium" style={{ marginRight: 8 }} color="secondary" />
                  <h4> tim@pspnm.com </h4>
                </Container>
              </Container>
            </Container>
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default Invite;
