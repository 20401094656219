// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles(() => ({
  main: {
    marginTop: 40,
  },
}));

const SqftTool = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'SQFT Tool',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/sqft`,
    page_path: '/sqft'
  });
  return (
    <>
      <CssBaseline />
      <Header />
      <Container className={classes.main} maxWidth="md">
      SqftTool Stuff goes here
      </Container>
      <Footer />
    </>
  );
};

export default SqftTool;
